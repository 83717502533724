@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

@import 'simplebar-css';
@import 'react-toastify-css';
@import 'react-pdf-annotation-css';

.design-v2 {
    @import '../../../fonts/Segoe UI/stylesheet.less';
    * {
        box-sizing: border-box;
        font-family: 'Segoe UI Local', sans-serif;
    }

}
* {
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', sans-serif;
    font-weight: var(--font-weight-n);
    font-style: normal;
    font-size: var(--font-size-p1);
    line-height: 1.5rem;
    background-color: var(--background-color);

    h1,
    h2,
    h3,
    h4 {
        color: var(--brand-color);
    }

    a {
        color: var(--link-color);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--link-color-hover);
        }
    }

    .primary-button.MuiButtonBase-root {
        width: 100%;
        height: 40px;
        box-shadow: 0 2px 4px var(--box-shadow-color);
        padding: var(--space) var(--space-2);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
        color: var(--button-color);
        background-color: var(--button-background-color);

        &:hover,
        &:focus {
            background-color: var(--button-background-color-hover);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);
            background-color: var(--button-background-color-disabled);
        }
    }

    .primary-button-inverted.MuiButtonBase-root {
        width: 100%;
        height: 40px;
        box-shadow: 0 2px 4px var(--box-shadow-color);
        padding: var(--space) var(--space-2);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
        color: var(--button-inverted-color);
        border-color: var(--button-inverted-color);
        background-color: var(--button-inverted-background-color);

        &:hover,
        &:focus {
            color: var(--button-inverted-color-hover);
            border-color: var(--button-inverted-color-hover);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);
            background-color: var(--button-background-color-disabled);
        }
    }

    .MuiFormControlLabel-root {
        margin-left: 0;
    }

    .MuiFormHelperText-root {
        font-size: var(--input-help-text-font-size);
        font-weight: var(--input-help-text-font-weight);
    }

    .MuiFormLabel-root {
        font-size: var(--switch-color);
        font-weight: var(--input-label-font-weight);
    }

    .MuiMenuItem-root {
        font-size: var(--input-label-font-size);
        font-weight: var(--input-label-font-weight);
    }

    .MuiIconButton-root {
        color: var(--brand-color);

        svg {
            color: var(--brand-color);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);

            svg {
                color: var(--button-color-disabled);
            }
        }
    }

    .MuiRadio-colorSecondary.Mui-checked:hover {
        background-color: var(--switch-check-radio-hover-bg);
    }

    .MuiTypography-root {
        &:not(.MuiPickersToolbarText-toolbarTxt) {
            color: var(--brand-color);
        }
    }

    .informational-highlight {
        color: var(--brand-color);
        font-size: var(--font-size-p2);

        &.animate {
            animation: informational-highlight 1.5s 3 linear;
            padding: var(--space);
            border-radius: var(--space);
        }
    }

    .MuiFormLabel-root.Mui-focused {
        color: var(--brand-color);
    }

    .signing-status {
        &.is-completed {
            background-color: var(--signer-is-completed-background-color);
            color: var(--signer-is-completed-color);
        }

        &.is-pending {
            background-color: var(--signer-is-pending-background-color);
            color: var(--signer-is-pending-color);
        }

        &.is-declined {
            background-color: var(--signer-is-declined-background-color);
            color: var(--signer-is-declined-color);
        }

        &.is-expires-soon {
            background-color: var(--signer-is-expires-soon-background-color);
            color: var(--signer-is-expires-soon-color);
        }

        &.is-expired {
            background-color: var(--signer-is-expired-background-color);
            color: var(--signer-is-expired-color);
        }
    }

    .MuiButton-root {
        font-size: var(--button-label-font-size);
        font-weight: var(--button-label-font-weight);
    }

    .MuiTableCell-root {
        &.MuiTableCell-head {
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-b1);
            color: var(--table-head-color);
        }

        &.MuiTableCell-body {
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
        }
    }

    .MuiTableHead-root {
        border-radius: 4px 4px 0 0;
        background-color: var(--table-row-accented-background-color);
    }

    .MuiTableCell-stickyHeader {
        background-color: var(--table-row-accented-background-color);
    }

    .MuiTableBody-root {
        border-radius: 0 0 4px 4px;
        background-color: var(--table-row-background-color);

        .MuiTableRow-root {
            min-height: 48px;
            height: 48px;
        }
    }

    .MuiTooltip-tooltip {
        font-size: var(--font-size-p2);
        font-weight: var(--font-weight-n);
    }

    .MuiSwitch-root {

        .MuiSwitch-switchBase,
        .MuiSwitch-colorSecondary {
            .MuiIconButton-label {
                color: var(--switch-icon-color);
            }

            +.MuiSwitch-track {
                background-color: var(--switch-track-color);
                opacity: 1;
            }

            &.Mui-checked {
                color: var(--brand-color);

                .MuiIconButton-label {
                    color: var(--switch-checked-icon-color);
                }

                +.MuiSwitch-track {
                    background-color: var(--switch-checked-track-color);
                    opacity: 1;
                }

                &:hover {
                    background-color: var(--switch-check-radio-hover-bg);
                }
            }
        }
    }

    .MuiList-root {
        .MuiMenuItem-root {
            background-color: var(--background-color-light);
            color: var(--brand-color);

            &:hover {
                background-color: var(--background-color-accented);
            }

            &.Mui-selected {
                font-weight: var(--font-weight-b2);
                background-color: var(--background-color-accented);
            }
        }
    }

    .MuiPopover-root {
        .MuiPopover-paper {
            background-color: var(--background-color-light);
            color: var(--brand-color-secondary);
        }
    }

    .MuiPaper-root.MuiAutocomplete-paper {
        .MuiAutocomplete-option {
            background-color: var(--background-color-light);
            color: var(--brand-color-secondary);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--background-color-accented);
                color: var(--brand-color);
            }
        }
    }

    .MuiRadio-root,
    .MuiCheckbox-root {
        color: var(--brand-color);

        &.Mui-checked {
            color: var(--brand-color);
        }

        &.Mui-disabled {
            color: var(--brand-color-inactive);
        }

        &:hover {
            background-color: var(--switch-check-radio-hover-bg);
        }
    }

    .MuiDialog-root {
        .MuiPickersDatePickerRoot-toolbar {
            background-color: var(--datepicker-toolbar-background-color);

            .MuiPickersToolbarText-toolbarTxt {
                h4 {
                    color: var(--brand-color);
                }

                h6 {
                    color: var(--brand-color-secondary);
                }
            }
        }

        .MuiPickersCalendarHeader-iconButton {
            color: var(--brand-color);
            background-color: inherit;
        }

        .MuiPaper-root {
            background-color: var(--datepicker-background-color);
        }

        .MuiPickersCalendarHeader-dayLabel,
        .MuiPickersDay-day {
            color: var(--datepicker-color);
        }

        .MuiPickersDay-dayDisabled {
            color: var(--brand-color-inactive);
        }

        .MuiDialogActions-root {
            .MuiButton-root:not(.app-button) {
                color: var(--brand-color);
            }
        }

        .MuiPickersDay-daySelected {
            color: var(--datepicker-selected-color);
            background-color: var(--datepicker-selected-background-color);

            .MuiTypography-root {
                font-weight: 700;
            }
        }
    }

    .Mui-focusVisible {
        outline: 2px solid var(--focus-color) !important;
    }

    .MuiLinearProgress-root {
        background-color: var(--linear-progress-bar-background-color);

        .MuiLinearProgress-barColorPrimary {
            background-color: var(--linear-progress-bar-color);
        }
    }

    .avatar {
        width: 32px;
        height: 32px;
        font-size: 13px;
        color: var(--background-color-accented);
        box-shadow: 3px 3px 8px var(--box-shadow-color);
        text-transform: uppercase;
        font-weight: var(--font-weight-b2);

        &.is-completed,
        &.is-package-completed {
            background-color: var(--signer-is-completed-background-color);
            color: var(--signer-is-completed-color);
        }

        &.is-pending {
            background-color: var(--signer-is-pending-background-color);
            color: var(--signer-is-pending-color);
        }
    }
}

.validsign {
    display: flex;
    flex-direction: column;
}

.simplebar-scrollbar::before {
    background-color: var(--brand-color);
}

.snackbar-container {
    .custom-icon {
        margin-right: var(--space);
    }

    .close-button {
        color: var(--button-color);
    }
}

// TODO: delete icon-spin animation in other places
@keyframes icon-spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes informational-highlight {
    0% {
        box-shadow: none;
    }

    50% {
        box-shadow: 0 0 10px 1px rgba(83, 154, 245, 0.8);
    }

    100% {
        box-shadow: none;
    }
}

.vs-custom-scrollbar {
    --scrollbar-width: 3px;
    --scrollbar-bg-color: transparent;
    --scrollbar-thumb-color: #90a2b8;

    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-bg-color);
        
    &::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-width);
    }
        
    &::-webkit-scrollbar-track {
        background: var(--scrollbar-bg-color);
    }
        
    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-color);
        border-radius: calc(var(--scrollbar-width) / 2);
    }
}
