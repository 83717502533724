@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.validsign-v2 {
    position: relative;

    .header-container {
        z-index: 100;
    }

    .curtain {
        position: absolute;
        background-color: var(--brand-color);
        height: var(--header-height);
        width: 100%;
        z-index: 1;

        &.should-show-curtain {
            height: calc(var(--header-height) + 280px);
        }
    }

    .main {
        width: 100%;
        z-index: 2;
    }
}
