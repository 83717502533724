@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.user-menu-list {
    .menu-sub-heading {
        color: var(--nav-link-color-inactive);
        background-color: var(--background-color-accented);
        padding-left: var(--space-2);
        font-size: var(--font-size-p3);
        font-weight: var(--font-weight-n);
        line-height: 30px;
    }

    .menu-sub-item {
        .sub-link {
            text-decoration: none;
            color: var(--nav-link-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
            padding: var(--space) var(--space-2) var(--space) var(--space-3);
            width: 100%;
        }

        &.is-active {
            background-color: var(--background-color-accented);

            .sub-link {
                font-weight: var(--font-weight-b2);
            }
        }

        &.Mui-disabled {
            opacity: 1;
        }

        &:hover .link {
            color: var(--nav-link-color-hover);
        }
    }
}
