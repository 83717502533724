@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.validsign {
    position: relative;

    .main {
        width: 100%;
    }
}
