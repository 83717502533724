@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.Toastify {
    .Toastify__toast-container {
        width: auto;
        max-width: 500px;
    }
}

.toast-notification-container {
    --toastify-toast-left: 20px;
    --toastify-toast-bottom: 0;
    --toastify-color-progress-light: '#fff';

    .toast-notification {
        background-color: var(--background-color-accented);
        box-shadow: 0 0 7px 4px var(--alert-box-shadow-color);
        min-width: 300px;
    }

    .toast-notification-body {
        color: var(--notification-text-color);
        font-size: var(--font-size-p1);
        font-weight: var(--font-weight-n);

        .toast-alert {
            display: flex;

            .toast-alert-msg-container {
                display: flex;

                .toast-alert-msg-count {
                    margin-left: var(--space);
                    font-weight: var(--font-weight-b3);
                }
            }

            .toast-alert-icon-container {
                display: flex;
                align-items: center;
                margin-right: var(--space);
            }

            .toast-alert-icon {
                width: 24px;
                height: 24px;
            }

            &.error {
                .toast-alert-icon {
                    color: var(--danger-color);
                }
            }

            &.success {
                .toast-alert-icon {
                    color: var(--success-color);
                }
            }
        }
    }

    .toast-close-button-container {
        display: flex;
        align-items: center;

        .toast-close-icon {
            width: 20px;
            height: 20px;
            color: var(--brand-color);
        }
    }

    .toast-notification-progress-bar {
        background: var(--brand-color);
        opacity: 1;
    }

    &.mobile-designer-toast {
        left: auto;
        right: 0;
        padding: 0;
        top: 0;

        &.Toastify__toast-container--bottom-left {
            display: none;
        }

        .toast-notification {
            min-width: 120px;
            min-height: 30px;
            padding: 2px 8px 8px 0;
        }

        .toast-close-button-container {
            display: flex;
            align-items: center;

            .toast-close-icon {
                width: 18px;
                height: 18px;
            }
        }

        .toast-notification-body {
            font-size: var(--font-size-p2);
        }
    }
}