@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.user-menu {
    color: var(--brand-color-secondary);

    svg {
        color: var(--brand-color);
    }

    .user-menu-avatar {
        color: var(--background-color-light);
        background-color: var(--background-color-accented);
    }

    .user-menu-button {
        height: var(--space-6);
        padding: 0 10px;
        text-transform: none;
        font-size: var(--font-size-p1);
        background-color: inherit;

        &:hover,
        &:focus {
            border-radius: var(--space-3);
        }

        .user-name {
            display: none;
            margin-right: var(--space-2);
            color: var(--brand-color);
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-b2);
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    @media (min-width: @screen-lg) {
        display: none;

        .user-menu-button {
            .user-name {
                display: block;
            }
        }
    }
}

.user-menu-list {
    .menu-item {
        opacity: 1;

        &.link {
            text-decoration: none;
            color: var(--brand-color);
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-n);
            padding: 11px var(--space-2);
            width: 100%;

            &.is-active {
                background-color: var(--background-color-accented);
                color: var(--nav-link-color);
                font-weight: var(--font-weight-b2);

                .menu-item-title {
                    .MuiTypography-root {
                        font-weight: var(--font-weight-b2);
                    }
                }
            }

            &:hover,
            &:focus {
                color: var(--brand-color);
            }

            &.sub-menu-header {
                padding: 0;

                .menu-item-title {
                    padding: 11px var(--space-2);
                    margin: 0;
                }
            }
        }

        &.Mui-disabled {
            opacity: 1;
        }

        &.MuiMenuItem-root.user-info-item {
            background-color: var(--background-color-accented);
        }

        &:hover,
        &:focus {
            .link {
                color: var(--nav-link-color-hover);
            }
        }
    }

    .user-menu-item-avatar {
        color: var(--brand-color);
        background-color: var(--background-color);
    }

    .chevron-icon {
        color: var(--brand-color-secondary);
    }
}

.user-menu-info-item {
    display: flex;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: var(--font-size-h3);
        border-radius: 50%;
        height: 40px;
        width: 40px;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        color: var(--brand-color);
    }
}
